* {
  font-family: 'Montserrat', sans-serif;
}
.App {
  min-height: 100vh;
  position: relative;
}
.globalHeader {
  background: #fff !important;
}
.longNameLogo {
  background: #fff;
  height: 30%;
  width: 35%;
}
.subLinks {
  background: #fff;
  border-bottom: 1px solid gainsboro;
  border-top: 1px solid gainsboro;
  padding: 10px 0px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  font-size: 80%;
}
.subLink {
  color: #000;
  margin-left: auto;
  margin-right: auto;
  opacity: 65%;
}
.subLink:hover {
  color: #000;
}
.subLink.active {
  color: #000;
  font-weight: 900;
  letter-spacing: 1px;
  opacity: 100%;
}
.pageBody {
  margin-top: 1rem;
  padding-bottom: 5rem;
}
.pageBodyMain {
  padding-bottom: 5rem;
}
.aboutUsProfileImg {
  float: right;
  margin: 5px;
  max-height: 300px;
  object-fit: cover;
  width: 300px;
}
@media (max-width: 992px) {
  .aboutUsProfileImg {
    float: none !important;
  }
  .howItWorksText {
    font-size: 1.5em !important;
    font-weight: 600 !important;
    letter-spacing: 5px !important;
  }
  .text {
    font-size: 12px !important;
    padding: 14px 28px !important;
  }
  .longNameLogo {
    width: 50% !important;
  }
  .addressBlock {
    margin-bottom: 15px !important;
    margin-top: 25px !important;
  }
  .contactInfo {
    margin-top: 20px !important;
  }
  /* .hamburgerMenu {
    background-color: #fff;
    border: 1px solid #e8e8e8;
    color: #000;
    cursor: pointer;
    display: flex !important;
    left: 25px;
    position: fixed;
    top: 25px;
    z-index: 10;
  }
  .hamburgerMenuOpen {
    background-color: #2a2a2a;
    border: none;
    color: #fff;
    cursor: pointer;
    display: flex !important;
    left: 25px;
    position: fixed;
    top: 25px;
    z-index: 10;
  }
  .subLinks {
    display: none !important;
  } */
}
@media (max-width: 767px) {
  .howItWorksText {
    font-size: 1em !important;
    font-weight: 600 !important;
    letter-spacing: 5px !important;
  }
}
.aboutUsContent {
  margin-top: 25px;
}
.appFooter {
  background: #f8f8ff;
  border-top: 0.125rem solid gainsboro;
  bottom: 0;
  display: block;
  height: 5rem;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  position: absolute;
  width: 100%;
}
.socialLinks {
  margin: 0rem 2.8125rem;
}
.subt {
  background: #f8f8ff;
  font-family: 'Montserrat';
  font-size: 0.5rem;
  margin-top: 0.5rem;
}
/* MAIN */
.reserveCol {
  margin-top: 50px;
  position: relative;
  width: 100%;
}
.imgCol {
  position: relative;
  width: 100%;
}
.mainDressImg {
  width: 100%;
  height: auto;
}
.mainTitleText {
  margin-top: 40px;
  margin-bottom: 25px;
}
.mainSubtitleText {
  margin-bottom: 25px;
  opacity: .7;
}
.mainFooterText {
  margin-bottom: 10px;
  opacity: .6;
  font-size: 14px;
  font-style: italic;
}
.pubLink {
  color: #000;
  margin-bottom: 25px;
  opacity: .5;
  margin-left: auto;
  margin-right: auto;
  text-decoration: underline;
}
.reserveImg {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}
.middleBtn {
  transition: .5s ease;
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 80%;
}
.text {
  background-color: #181818;
  color: #fff;
  font-size: 16px;
  padding: 16px 32px;
  border-radius: 5px;
  border: 1px solid #fff;
}
.text:hover {
  border: 1px solid #000;
  color: #fff;
  text-decoration: none;
}
.igLink {
  position: relative;
}
.igImg {
  max-width: 19em;
  border: solid;
  border-color: #f8f8ff;
  border-bottom: 10px;
  border-top: 10px;
  margin-bottom: 5px;
}
.igImg:hover {
  color: black;
  cursor: url('https://ik.imagekit.io/r596hampx/tr:w-20/insta_upynJ6i0W.png'), default;
}
.igImg:hover {
  opacity: 0.3;
}
.vPlayerWrapper {
  padding-left: 0px;
  padding-right: 0px;
}
.vPlayerRow {
  margin-left: 0px;
  margin-right: 0px;
}
.instafeedContainer{
  margin-top: 50px;
}
/* EVENT PAGE */
.eventBorder {
  width: 50%;
  margin-bottom: 10px;
}
.eventLink {
  color: #000;
  padding: 40px 80px
}
.eventLink:hover {
  color: #000;
}
.eventLink:hover .eventDate {
  text-decoration: underline;
}
.eventDate {
  padding-right: 30px;
}
.eventTitle {
  font-weight: 900;
  font-size: 22px;
}
/* CONTACT */
.contact {
  margin-left: auto;
  margin-right: auto;
  text-align: left !important;
}
.pageBodyContact {
  padding-bottom: 7.5rem;
}
.containerContact {
  width: 100%;
}
.italicStyle {
  font-style: italic;
}
.btnSubmit {
  background-color: #181818;
  border: 1px outset #fff;
  color: #fff;
  letter-spacing: 1px;
  margin-bottom: 16px;
}
.btnSubmit:hover {
  border: 1px outset #000;
  color: #fff;
}
.contactInfo {
  margin-top: 45px;
}
.emailLink {
  color: #000;
  font-size: 18px;
  white-space: nowrap
}
.phoneLink {
  color: #000;
  font-size: 18px;
  white-space: nowrap
}
.address {
  color: #000;
  font-size: 18px;
  margin-bottom: 20px;
}
.addressBlock {
  margin-top: 50px;
}
.bizCard {
  border: 5px solid #666666;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 350px;
  max-height: 350px;
}
.bizCard:hover {
  border: 1px solid #666666;
  padding: 4px;
}
/* HOW IT WORKS */
.howItWorksText {
  color: #000;
  font-size: 2em;
  font-weight: 800;
  letter-spacing: 10px;
  overflow-wrap: normal;
}
.middlePlacement {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.pageBodyHowItWorks {
  padding-bottom: 8rem;
}
.hIWNumbers {
  color: #000;
  font-size: xx-large;
  font-weight: 600;
  padding: 5px 0px;
}
.hIWTitle {
  color: #2a2a2a;
  font-size: x-large;
  padding-top: 7px;
  text-align: left;
}
.hIWbody {
  color: #818181;
  text-align: left;
}
.hIWRow {
  padding-top: 40px;
}
/* Sidebar */
.navBar {
  position: relative;
}
.hamburgerMenu {
  display: none;
}
.menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  background: #2a2a2a;
  left: -40px;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  max-width: 340px;
  z-index: 9;
}
.menuNav.showMenu {
  width: 100%;
}
/* .mobileSubLinks {
  display: grid;
}
.mobileSubLink {
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  opacity: 65%;
}
.mobileSubLink:hover {
  color: #fff;
  opacity: 100%;
} */

/* GALLERY */
.lookbookPreview {
  border: 5px solid #666666;
  max-width: 400px;
  max-height: 400px;
}
.lookbookPreview:hover {
  border: 1px solid #666666;
  padding: 4px;
}
.lookbookTitle {
  color: #000;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.galleryBtn {
  background: none;
  border: none;
  padding: 0;
}
.collectionBox {
  margin-bottom: 20px;
}
.notes__inner__image img {
   max-width: 100%;
}
.galleryRow {
  border-bottom: 1px solid #666666;
  padding-bottom: 20px;
  padding-top: 20px;
}
.swiper-button-next {
  color: #000 !important;
}
.swiper-button-prev {
  color: #000 !important;
}
.swiper-pagination-bullet-active {
  background: #000 !important;
}
.lookbookRow {
  padding-top: 20px;
}
.alogo {
  margin-bottom: -7px;
  margin-top: -12px;
  max-height: 55px;
  max-width: 55px;
}